<template>
  <div class="file-uploader">
    <el-upload
      :action="upyunUrl + '/' + bucket"
      :multiple="false"
      :data="uploadData"
      drag
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-success="uploadSuccess"
    >
      <el-image v-if="value" :src="bzhUrl + '/file/download/' + value" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "image-upload",
  props: {
    value: {
      type: [String, Number],
    },
    topFolder: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      upyunUrl: "",
      bucket: "",
      domain: "",
      uploadData: {},
      imageUrl: "",
    };
  },
  computed: {
    bzhUrl: {
      get() {
        return this.$http.getBzhUrl();
      },
    },
  },
  methods: {
    async uploadSuccess(res) {
      let saveData = await this.$http.doApi("fw-bzh-saveFile", {}, res);
      this.$emit("input", saveData.val);
    },
    async beforeUpload() {
      let data = await this.$http.doApi(
        "fw-bzh-getPolicyAndAuthorization",
        {
          topFolder: this.topFolder,
        },
        {}
      );
      this.uploadData = data;
    },
    async loadUpyunConfig() {
      let configData = await this.$http.doApi("fw-bzh-getUpCloudConfig");
      this.upyunUrl = configData.url;
      this.bucket = configData.bucket;
      this.domain = configData.domain;
    },
  },
  created() {
    this.loadUpyunConfig();
  },
};
</script>

<style >
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
</style>