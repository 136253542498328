<template>
  <div class="wx-event-handler-edit-dialog">

    <div class="basic-title">
      基本信息
    </div>
    
    <el-form
      ref="form"
      inline
      v-loading="loading"
      size="mini"
      :model="formData"
      label-width="100px"
      label-suffix=":"
      :rules="formRules"
    >
      <el-form-item label="封面图" prop="coverImg">
        <image-upload v-model="formData.coverImg" />
      </el-form-item>
      <br />
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="formData.name"
          clearable
          placeholder="请输入处理器名称"
        />
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input
          v-model="formData.description"
          clearable
          placeholder="请输入处理器描述信息"
        />
      </el-form-item>
      <el-form-item label="状态" prop="flag">
        <el-select v-model="formData.flag">
          <el-option :value="1" label="启用"></el-option>
          <el-option :value="0" label="停用"></el-option>
          <el-option :value="-1" label="删除"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Bean名称" prop="beanName" >
        <el-input
          v-model="formData.beanName"
          clearable
          placeholder="请输入处理器类名"
        />
      </el-form-item>
      <el-form-item label="匹配模式" prop="matchMode">
         <el-select v-model="formData.matchMode">
                <el-option :value="1" label="半匹配"></el-option>
                <el-option :value="2" label="全匹配"></el-option>
                <el-option :value="3" label="正则"></el-option>
                <el-option :value="4" label="BeanShell"></el-option>
              </el-select>
      </el-form-item>

      <br />
      <el-form-item label="表达式" prop="expression" class="form-item-expression">
        <el-input type="textarea" resize="both" v-model="formData.expression" />
      </el-form-item>


      <table class="param-define-table">
        <thead>
          <tr>
            <th colspan="6">自定义参数列表</th>
          </tr>
          <tr class="title">
            <th style="width: 5%">序号</th>
            <th style="width: 15%">Label</th>
            <th style="width: 17%">Key</th>
            <th style="width: 15%">类型</th>
            <th style="width: 35%">默认值</th>
            <th style="width: 8%">
              操作&nbsp;<el-button
                type="text"
                icon="el-icon-plus"
                class="add-btn"
                @click="addParamDefine"
              ></el-button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="param-define-row"
            v-for="(item, index) in paramDefineList"
            :key="item.key"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <el-input
                v-model="item.label"
                size="mini"
                clearable
                placeholder="请输入标签"
              />
            </td>
            <td>
              <el-input
                v-model="item.fieldName"
                size="mini"
                clearable
                placeholder="请输入字段名"
              />
            </td>
            <td>
              <code-domain-select v-model="item.fieldType" code-domain="domain.FieldType" />
            </td>
            <td>
              <el-input
                v-model="item.defVal"
                size="mini"
                clearable
                placeholder="请输入参数默认值"
              />
            </td>
            <td>
              <el-button
                type="text"
                icon="el-icon-close"
                class="add-btn"
                @click="removeParamDefine(item.key)"
              ></el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </el-form>
  </div>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
export default {
  name: "wx-event-handler-edit-dialog",
  props: {
    id: {
      type: Number,
      required: false,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        flag: 1,
      },
      paramDefineList: [],
      formRules: {
        name: [
          { required: true, message: "处理器模板名称必填", trigger: "blur" },
        ],
        description: [
          { required: true, message: "处理器模板描述必填", trigger: "blur" },
        ],
        className: [
          { required: true, message: "处理器模板类名必填", trigger: "blur" },
        ],
        flag: [
          { required: true, message: "处理器模板状态必选", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async opened() {
      this.loadTemplateData(this.id);
    },
    async loadTemplateData(id) {
      if (!id || id <= 0) {
        return;
      }
      try {
        this.loading = true;
        let data = await this.$http.doApi("fw-wx-event-template-getComb", { id });
        this.formData = {
          ...data.wxEventHandlerTemplate,
        };
        let tmpParamDefineList = data.wxEventHandlerTemplateParamDefine;
        for (let i = 0; i < tmpParamDefineList.length; i++) {
          this.paramDefineList.push({
            ...tmpParamDefineList[i],
            key: tmpParamDefineList[i].id,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    addParamDefine() {
      this.paramDefineList.push({
        key: Date.now(),
      });
    },
    removeParamDefine(key) {
      let idx = -1;
      for (let i = 0; i < this.paramDefineList.length; i++) {
        if (key === this.paramDefineList[i].key) {
          idx = i;
        }
      }
      if (idx >= 0) {
        this.paramDefineList.splice(idx, 1);
      }
    },
    async getData() {
      await this.$refs.form.validate();
      let data = {
        wxEventHandlerTemplate: { ...this.formData }
      };
      data.wxEventHandlerTemplateParamDefine = [];
      for (let i = 0; i < this.paramDefineList.length; i++) {
        let item = {
          ...this.paramDefineList[i],
        };
        delete item.key;
        data.wxEventHandlerTemplateParamDefine.push(item);
      }
      return data;
    },
  },
  components: {
    ImageUpload,
  },
};
</script>

<style lang="less">
.wx-event-handler-edit-dialog {
  .basic-title {
    font-weight: bold;
    width: 95%;
    margin: 0 auto 1rem auto;
      border-bottom: @hair-border-ccc;
  }

  .form-item-expression {
    width: 90%;
     .el-form-item__content {
      width: 70%;
     }
  }

  .param-define-table {
    width: 95%;
    margin: 0 auto;
    border-collapse: collapse;
    text-align: center;

    thead {
      tr:first-child {
        text-align: left;
        border-bottom: @hair-border-ccc;
        th {
          padding-bottom: .4rem;
        }
      }

      .title {
        border-bottom: @hair-border-ccc;
        border-left: @hair-border-ccc;
        background-color: #e0e0e0;
        th {
          padding: .1rem 0;
          border-right: @hair-border-ccc;
        }
      }
    }

    .param-define-row {
      input {
        border: 0;
        width: 100%;
      }
      .el-select {
        width: 100%;
      }
    }
    .add-btn {
      padding: 0px;
    }
    th {
      padding: 1px;
      height: 0.8rem;
    }
    td {
      border: 1px #e7e4e4 solid;
    }
  }
}
</style>